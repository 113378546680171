import { Component } from '@angular/core';
import { trackById } from '../../../utils/object';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import {
  CampaignEstimationPart,
  Creative,
  GeoTargeting,
  Targeting,
  wooId,
} from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore, BookingTargetingMetaData } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'campaign-distribution',
  templateUrl: './campaign-distribution.component.html',
  styleUrls: ['./campaign-distribution.component.scss'],
})
export class CampaignDistribution extends SubscriptionHandler {
  targetings: Targeting[];
  creatives: Creative[];
  estimationParts: CampaignEstimationPart[];
  targetingMetaData: Record<string, BookingTargetingMetaData>;
  ttvCampaign = false;

  constructor(bookingStore: BookingStore, public formatterService: FormatterService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: BookingModel): void => {
    this.ttvCampaign = model.campaign.ttv_campaign;
    this.targetings = model.campaign.targetings;
    this.estimationParts = model.estimation.parts;
    this.targetingMetaData = model.targetingMetaData;
  };

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  showDistributeRegions = (targeting: Targeting): boolean => {
    return this.targetingMetaData[targeting.id].geoTargeting === GeoTargeting.regional && targeting.regions.length > 1;
  };
}

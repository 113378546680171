<div class="inner-block">
  <div class="row">
    <div class="col-xs-12">
      <dot-loader [visibility]="!agencyLoaded" description="Hämtar byrå..."></dot-loader>
      <div *ngIf="agencyLoaded">
        <div class="account__card">

        <div class="pull-right text--gray">
          <p *ngIf="agency.crm_last_modified_at" class="text--small">
            <span class="icon-refresh"></span> Uppdaterad i CRAM <span>{{agency.crm_last_modified_at | wooDate: WooDateFormat.Distance}}</span>
          </p>
          <p *ngIf="agency.crm_last_synced_at" class="text--small">
            <span class="icon-refresh"></span> Synkad från CRAM <span>{{agency.crm_last_synced_at | wooDate: WooDateFormat.Distance}}</span>
          </p>
        </div>
        <h2 class="margin--none">{{agency.name}}</h2>
        <div *ngIf="agency.locked" class="text--cta margin-top-bottom--2x">
          <span class="icon-warning"></span>
          Denna byrå är låst och kan inte logga in eller boka kampanjer.
        </div>
        <h5 *ngIf="!isAdminPlannerAccounting">Hör av dig till din säljare om informationen som inte går att redigera nedan inte stämmer, så ser vi till att ändra den så snart som möjligt.</h5>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div *ngIf="agency.nickname && isAdminPlannerAccounting" class="card__info">
              <span class="text--medium">Kundnamn TV4:</span><br>
              <span>{{agency.nickname || '-'}}</span>
            </div>
            <div class="card__info">
              <ul class="reset--list inline--list">
                <li *ngFor="let agencyType of agencyTypes" class="margin-right--1x">
                  <div class="tab tab rounded">
                    <span *ngIf="agencyType === 'media_agency'">Mediebyrå</span>
                    <span *ngIf="agencyType === 'digital_media_agency_with_discount'">Digital mediebyrå med avtalsrabatter</span>
                    <span *ngIf="agencyType === 'partner'">Partner</span>
                    <span *ngIf="otherAgency(agencyType)">Annan byrå</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card__info">
              <span class="text--medium">Org. nr.</span><br>
              <span>{{agency.org_no}}</span>
            </div>
            <div *ngIf="isAdminPlannerAccounting" class="card__info">
              <span class="text--medium">CRM-id</span><br>
              <span>{{agency.crm_id || '-'}}</span>
            </div>
            <div *ngIf="isAdminPlannerAccounting" class="card__info">
              <span class="text--medium">TSC-id</span><br>
              <span>{{agency.tsc_id || '-'}}</span>
            </div>
            <div class="card__info">
              <span class="text--medium">Förmedlingsprovision</span><br>
              <div class="status__badge with--icon small float--left margin-right--2x" [ngClass]="{'success': agency.has_discount, 'error': !agency.has_discount}"></div>
              <span *ngIf="agency.has_discount"> {{agency.agency_discount | wooCurrency : WooCurrencyValue.Percent : 2}}</span>
              <span *ngIf="!agency.has_discount">Nej</span>
            </div>
            <div  class="card__info" [hidden]="hideSelfService">
              <span class="text--medium">Administrationsersättning</span><br>
              <div class="status__badge with--icon small float--left margin-right--2x" [ngClass]="{'success': agency.self_service_compensation !== selfServiceCompensationValue.inactive, 'error': agency.self_service_compensation === selfServiceCompensationValue.inactive}"></div>
              <span *ngIf="agency.self_service_compensation !== selfServiceCompensationValue.inactive"> {{agency.self_service_compensation | wooCurrency : WooCurrencyValue.Percent : 2}}</span>
              <span *ngIf="agency.self_service_compensation === selfServiceCompensationValue.inactive">Nej</span>
            </div>
            <div class="card__info" *ngIf="!editShow" [hidden]="hideSelfService">
              <span class="text--medium">Mottagare av ersättningsutskick</span><br>
              <span>{{agency.woo_contact_email || '-'}}</span>
            </div>
            <form (ngSubmit)="update(agency)" *ngIf="editShow" [hidden]="hideSelfService">
              <div *ngIf="!isAgencyAdmin">
                <div class="toggle__container">
                  <h5 class="margin--none animate" [ngClass]="{'text-muted': agency.self_service_compensation !== selfServiceCompensationValue.inactive}">Av</h5>
                </div>
                <div class="toggle__container">
                  <input type="checkbox" id="calculate_from_budget" name="checkbox" class="cbx hidden"
                    [checked]="agency.self_service_compensation === selfServiceCompensationValue.active" (change)="toggleSelfServiceCompensation()">
                  <label class="lbl center" for="calculate_from_budget"></label>
                </div>
                <div class="toggle__container">
                  <h5 class="margin--none animate" [ngClass]="{'text-muted': agency.self_service_compensation === selfServiceCompensationValue.inactive}">På</h5>
                </div>
              </div>
              <div class="input__container">
                <label for="agencyadminreportmail">Mottagare av ersättningsutskick</label>
                <input
                  id="agencyadminreportmail"
                  name="agencyadminreportmail"
                  type="text"
                  [(ngModel)]="agency.woo_contact_email"
                  [disabled]="agency.self_service_compensation === selfServiceCompensationValue.inactive">
                <span class="input__help__text">Separera flera mailadresser med komma-tecken (,)</span>
              </div>
              <div class="clearfix">
                <input type="submit" value="Uppdatera" [disabled]="!validListOfEmails(agency)" class="button pull-left primary-1">
                <button type="button" class="link primary-1" (click)="closeCard('edit')">Avbryt</button>
              </div>
            </form>

          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="address__info">
              <span class="text--medium">Faktureringsadress</span><br>
              <div>{{agency.address_1}}</div>
              <div *ngIf="agency.address_2 !== null">
                <span>{{agency.address_2}}</span>
              </div>
              <div>
                <span *ngIf="agency.address_2 !== null">{{agency.address_2}}</span>
                <span>{{agency.zip_code}}</span><span> {{agency.city}}</span>
              </div>
              <div>{{agency.country}}</div>
            </div>
            <div class="card__info">
              <span class="text--medium">Land, säte</span><br>
              <span>{{agency.hq_country}}</span>
            </div>
            <div class="card__info">
              <span class="text--medium">Fakturera kunder direkt</span><br>
              <div class="status__badge with--icon small float--left margin-right--2x" [ngClass]="{'success': agency.invoice_customer, 'error': !agency.invoice_customer}"></div>
              <span *ngIf="agency.invoice_customer">Ja</span>
              <span *ngIf="!agency.invoice_customer">Nej</span>
            </div>
            <div class="card__info">
                <span class="text--medium">God kreditvärdighet</span><br>
                <div
                  class="status__badge with--icon small float--left margin-right--2x"
                  [ngClass]="{'success': !agency.low_credit_rating, 'error': agency.low_credit_rating}"></div>
                <span *ngIf="!agency.low_credit_rating"> Ja</span>
                <span *ngIf="agency.low_credit_rating"> Nej</span>
            </div>
            <div class="card__info">
              <span class="text--medium">Betalningsvillkor</span><br>
              <span>{{agency.payment_terms}} dagar</span>
              <span *ngIf="agency.payment_terms_code"> - {{agency.payment_terms_code}}</span>
            </div>
          </div>
          <div class="row" [hidden]="hideSelfService">
            <div class="col-xs-12">
              <button
                *ngIf="(isAdminPlannerAccounting || isAgencyAdminAtAgency(currentUser, agency.id)) && !editShow"
                class="button primary-1"
                (click)="openCard('edit')">Redigera</button>
            </div>
          </div>
        </div>
        </div>
        <div id="lists" class="row" *ngIf="mayViewCustomersAndUsers">
          <div class="col-xs-12 col-sm-6">
            <div class="tabbable__panel clearfix shadow-z-1">
              <div class="col-xs-12">
                <h3><span class="icon-shopping-cart"></span> Kunder</h3>
              </div>
            </div>
            <div class="account__card margin--none padding--none clearfix">
              <table class="table table--woo table--hover table--align--middle">
                <tbody>
                  <tr *ngFor="let agreement of agency.partner_agreements">
                    <td>
                      <a class="pointer" [routerLink]="['/customer', agreement.customer.id]">
                        {{agreement.customer.name}}
                      </a>
                    </td>
                    <td>
                      <span class="text--small pull-right">
                        <span *ngIf="agreement.agency_type === 'media_agency'">Mediebyrårelation</span>
                        <span *ngIf="agreement.agency_type === 'digital_media_agency_with_discount'">Digital mediebyrå med avtalsrabattsrelation</span>
                        <span *ngIf="agreement.agency_type === 'partner'">Partnerrelation</span>
                        <span *ngIf="otherAgency(agreement.agency_type)">Annanbyrårelation</span>
                        <span *ngIf="agreement.valid_from?.length" > från {{agreement.valid_from | wooDate }}</span>
                        <span *ngIf="agreement.valid_to?.length"> till {{agreement.valid_to | wooDate }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="agency.inactive_customers.length">Tidigare kunder</h4>
            <div class="account__card margin--none padding--none clearfix">
              <table class="table table--woo table--hover table--align--middle">
                <tbody>
                  <tr *ngFor="let customer of agency.inactive_customers">
                    <td>
                      <a class="pointer" [routerLink]="['/customer', customer.id]">
                        {{customer.name}}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="tabbable__panel clearfix shadow-z-1">
              <div class="col-xs-12">
                <h3><span class="icon-User"></span> Användare</h3>
              </div>
            </div>
            <div class="account__card margin--none padding--none clearfix">
              <table class="table table--woo table--hover table--align--middle">
                <tbody>
                  <tr *ngFor="let user of agency.users">
                    <td>
                      <a class="pointer" [routerLink]="['/user', user.id]">
                        {{user.first_name}} {{user.last_name}}<span class="text--italic" *ngIf="user.agency_admin"> (admin)</span>
                      </a>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="button danger pull-right"
                        (click)="removeUserFromAgency(user, agency)"
                        *ngIf="isAdminOrAgencyAdmin && (user.id != currentUser?.id)">Ta bort</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button *ngIf="canAddUser" class="button secondary pull-right" (click)="openCard('user')">Ny användare</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div #newUserContainer id="newItemInAgency" class="account__card away" [ngClass]="{away: (!newUser)}">
        <div *ngIf="newUser" >
          <h3>Skapa nytt användarkonto för {{agency.name}}</h3>
          <form #newUserForm="ngForm" name="newUserForm" (ngSubmit)="addUserWithRole(newUserObj, agency.id, role)">
            <div class="input__container">
              <label for="firstname">Förnamn</label>
              <input id="firstname" name="firstname" type="text" [(ngModel)]="newUserObj.first_name" required>
            </div>
            <div class="input__container">
              <label for="firstname">Efternamn</label>
              <input id="lastname" name="lastname" type="text" [(ngModel)]="newUserObj.last_name" required>
            </div>
            <div class="input__container">
              <label for="firstname">Email</label>
              <input name="email" id="emailuser" type="email" [(ngModel)]="newUserObj.email" required>
            </div>
              <woo-dropdown
              [items]="availableRoles"
              [(ngModel)]="role"
              bindLabel="name"
              bindValue="id"
              name="role"
              inputId="role"
              labelText="Roll"
              [searchAttributes]="['name']"
              notFoundText="Inga roller hittades."
              [clearable]="true"
            ></woo-dropdown>
            <input type="submit" value="Skapa" class="button primary-1 pull-left" [disabled]="!newUserForm.valid || !role">
            <button type="button" class="link primary-1" (click)="closeCard('user')">Avbryt</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="margin-bottom--3x">Lägg till material och ange fördelning</h2>

<div>
  <div
    class="targeting__container"
    *ngFor="let targeting of targetings; index as index; trackBy: trackById"
    [attr.e2e-id]="'distribution-' + index"
  >
    <expandable-targeting-header
      [expanded]="true"
      [targeting]="targeting"
      [targetingMetaData]="targetingMetaData[targeting?.id]"
    >

      <div class="row">
        <div
          *ngIf="!ttvCampaign"
          class="col-xs-9 col-sm-6 col-md-5 col-lg-3"
        >
          <targeting-budget [targetingId]="targeting.id"></targeting-budget>
        </div>
      </div>

      <div class="divider medium margin-top-bottom--2x"></div>
      <h4>Fördelning material</h4>
      <ng-container *ngIf="!ttvCampaign; else ttvCampaignComponent">
        <creatives-distribution [targetingId]="targeting.id"></creatives-distribution>
      </ng-container>
      <ng-template #ttvCampaignComponent>
        <ttv-creatives-distribution [targetingId]="targeting.id"></ttv-creatives-distribution>
      </ng-template>

      <div *ngIf="showDistributeRegions(targeting)">
        <div class="divider medium margin-top-bottom--2x"></div>
        <h4>Fördelning regioner</h4>
        <region-distribution
          [targetingId]="targeting.id"
        ></region-distribution>
      </div>
    </expandable-targeting-header>

  </div>
</div>
